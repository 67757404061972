@use 'Common.module.scss';

.page {
  position: relative;
  margin: auto;
  padding-left: 24px;
  padding-right: 24px;
  max-width: 800px;
  min-height: 100vh;
}

.header {
  //background-color: Common.$background-color;
  //min-height: 100vh;
  //padding: 10px 0px;
  display: flex;
  //flex-direction: column;
  align-items: center;
  justify-content: space-between;
  font-size: calc(7px + 2vmin);
  //color: white;
  border-bottom: solid 1px gray;
}

.bannerLogo {
  //height: 30px;
  //transform: translateZ(0);
}
.nav {
  //align-self: flex-end;
  // border-top: solid 1px gray;
  // border-bottom: solid 1px gray;
}

.navAction {
  display: inline-block;
  padding: 16px;

  cursor: pointer;
  user-select: none;
  color: #222;
  text-decoration: none;

  transition: background-color Common.$transitionDuration,
    color Common.$transitionDuration;

  &:hover {
    color: white;
    background-color: #35ace0;
  }
}

.navInfo {
  color: 'black';
  font-size: 18px;
}

