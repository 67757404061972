.footerSpacing {
  height: 80px;
}

.bannedLogosContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
}

.bannedLogos {
  margin-bottom: 10px;
}

