

.welcomePage {
  position: relative;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.window {
  font-size: 21px;
  width: 70%;
  max-width: 700px;
}

.bannerLogo {
  text-align: center;
  width: 100%;
  max-width: 500px;
}

