@use './Common.module.scss';

.post {
  border: solid 1px black;
  border-radius: 10px;
  overflow: hidden;
}

.equal {
  flex-basis: 0;
  flex-grow: 1;
}

.title {
  font-size: x-large;
}

.editableTitle {
  &::placeholder {
    color: rgb(190, 190, 190);
  }
}

.textTitle {
  @extend .title;
  margin: 10px;
}

.placeholderImageContainer {
  height: 540px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageContainer {
  position: relative;
  border-bottom: solid 1px black;
}

.replaceableContainer {
  //background-color: rgb(243, 243, 243);
  cursor: pointer;
}

.image {
  display: block;
  max-width: 100%;
  max-height: 500px;
  margin: auto;
}

.placehodlerImage {
  display: block;
  width: 50%;
  max-width: 260px;
  margin: auto;
}

.imageTitle {
  @extend .title;

  position: absolute;
  bottom: 0;
  left: 0;
  //right: 0;
  padding: 10px 14px 10px 10px;
  //padding-right: 14px;
  border-top-right-radius: Common.$borderRadius;

  color: whitesmoke;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(2px);
}

.reactionsContainer {
  position: absolute;
  bottom: 0;
  right: 0;

  overflow: hidden;
  border-top-left-radius: Common.$borderRadius;

  user-select: none;
}

.reaction {
  display: inline-block;
  padding: 10px;

  cursor: pointer;

  transition: background-color Common.$transitionDuration;
  background-color: rgba(255, 255, 255, 0.5);
  &:hover {
    background-color: rgba(255, 255, 255, 0.8);
  }
}



.youtubeLinkContainer {
  margin: 10px;
}
.youtubeLink {
  color: darkblue;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
}

.postInfo {
  margin: 10px;
}

.text {
  margin: 10px;
}
